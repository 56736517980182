import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <h1>TRN Solutions Inc.</h1>
        <p>Your Partner in Online Marketing Services</p>
      </header>
      <main className="Home-main">
        <p>Providing excellent online marketing services with a touch of flair.</p>
        <p>
          <a href="tel:19412338665">
            <img src="https://marketingwithflair.com/images/call-us.png" alt="Call Us - TRN Solutions Inc." />
          </a>
        </p>
            <p className="bigphone">
        <b>
          <a href="tel:+19412338665" className="simplelink">
            941-233-8665
          </a>
          &nbsp;|&nbsp;
          <a href="mailto:info@marketingwithflair.com" className="simplelink">
            info@marketingwithflair.com
          </a>
        </b>
      </p>
      <section className="Home-info">
        <h2>Why Partner with TRN Solutions?</h2>
          <p>
          At TRN Solutions Inc., we excel in delivering top-notch online sales services tailored to drive your business growth.
          Our dedicated team of sales professionals brings a wealth of experience and a commitment to exceptional customer care.
          We specialize in a variety of campaigns, leveraging our diverse expertise across multiple industries to enhance your
          business's reach and impact. Partner with us to experience unparalleled service and results.
          </p>
      </section>
      </main>
    </div>
  );
}

export default Home;
