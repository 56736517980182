import React from 'react';
import ContactForm from './ContactForm';

function About() {
  return (
    <div className="About">
      <header className="About-header">
        <h1>TRN Solutions Inc.</h1>
      </header>
      <main className="About-main">
        <p>We specialize in providing top-notch online marketing services for your business, infused with the charisma of Rick Flair.</p>
        <ContactForm />
      </main>
    </div>
  );
}

export default About;

